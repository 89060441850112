$root: hsl(43deg 100% 50%);
$second: hsl(359deg 97% 14%);

$third : rgb(69 0 2);

$four : #f3f4f8;

$five : rgba(255, 255, 255, 0.9);

$six : rgba(206, 85, 85, 1);

$background_one : #5b5b5b;
