/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/variables.scss";

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 24px;
  color: #777;
  font-size: 0.9em;
  background-color: #fafafa;
}

.title {
  text-align: center;
  color: #555;
  margin-bottom: 15px;
  font-size: 35px;
  font-weight: 500;
}

.btn {
  border-radius: 10px;
  color: rgb(95 41 41);
  border: 2px solid rgb(95 41 41);
  background-color: transparent;
  text-shadow: none;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.97em;
  letter-spacing: 0.03em;

  &:hover {
    color: #fff;
    background-color: rgb(95 41 41);
  }

  span {
    margin: 5px;
  }

  .icon {
    font-size: 12;
  }
}

.row {
  max-width: 1050px;
  margin: 0 auto;
}

a {
  color: #777;

  &:hover {
    text-decoration: unset;
    color: #000;
  }
}

.phone {
  color: $four;
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
